.Footer-container {
  position: relative;
}
.Footer-container > hr {
  border: 1px solid var(--lightgray);
}
.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
.social-links {
  display: flex;
  gap: 4rem;
}
.social-links > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.logo-f > img {
  width: 10rem;
}
.blur-f-1 {
  margin-top: -11rem;
  margin-bottom: 15rem;
  margin-left: 52%;
  width: 40rem;
  height: 5rem;
  filter: blur(200px);
  background: red;
}
.blur-f-2 {
  margin-top: -26rem;
  margin-bottom: 15rem;
  margin-left: 19%;
  width: 26rem;
  height: 6rem;
  filter: blur(200px);
  background: rgb(255, 115, 0);
}
