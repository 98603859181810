.Join {
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
}
.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.left-j > hr {
  width: 10.5rem;
  margin: -10px 0;
  border: 2px solid var(--orange);
  border-radius: 20%;
  position: absolute;
}
.left-j > div {
  display: flex;
  gap: 1rem;
}
.right-j {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.email-container {
  display: flex;
  background-color: gray;
  gap: 3rem;
  padding: 1rem 2rem;
  height: 2rem;
}

.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder {
  color: var(--lightgray);
}
.btn-j {
  color: white;
  background-color: var(--orange);
  border: none;
  border-radius: 3px;
}
.btn-j:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Join {
    flex-direction: column;
    gap: 1rem;
  }
  .left-j {
    font-size: x-large;
    flex-direction: column;
  }
  .right-j {
    padding: 2rem;
  }
  .btn-j{
    width: 3.6rem;
  }
}
